<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">View support ticket</h1>
        <div class="row gap-20 center">
          <button v-if="!editMode" @click="toggle" class="center gap-10">
            <s-icon color="white" height="16">pencil-outline</s-icon>
            <span class="font-14 opacity-75 weight-300">Edit</span>
          </button>
          <div class="center gap-10" v-if="editMode">
            <s-btn @click.native="close"> Close </s-btn>
            <s-btn @click.native="post" class="green"> Save </s-btn>
          </div>
          <router-link
            v-if="!editMode"
            to="/support"
            class="row align-end gap-10 mb-2"
          >
            <s-icon color="white" height="20">chevron-left</s-icon>
            <div class="my-0">Back</div>
          </router-link>
        </div>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 col">
      <div class="w600 weight-300">Support ticket details</div>
    </section>

    <section class="w600 mx-auto mb-20">
      <div class="grid">
        <div class="label">Title</div>
        <s-text-field
          class="item"
          :noedit="editMode"
          v-model="title"
        ></s-text-field>
        <div class="label">Status</div>
        <s-dropdown
          class="item"
          :noedit="!editMode"
          customPlaceholder="Please select category"
          :height="40"
          noSelect="true"
          v-model="status"
          :items="itemsStatus"
        ></s-dropdown>
        <div class="label">Category</div>

        <s-dropdown
          :noedit="!editMode"
          customPlaceholder="Please select category"
          :height="40"
          class="item"
          noSelect="true"
          v-model="category"
          :items="itemsCategories"
        ></s-dropdown>
        <div class="label">Description</div>
        <s-text-field
          class="item"
          :noedit="editMode"
          v-model="description"
        ></s-text-field>
        <div class="label">Ticket No.</div>
        <s-text-field
          class="item"
          :noedit="false"
          v-model="supportTicket.id"
        ></s-text-field>
        <div class="label">Created at</div>
        <s-text-field
          class="item"
          :noedit="false"
          v-model="createdAt"
        ></s-text-field>
      </div>
    </section>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      editMode: false,
      title: "",
      category: "",
      description: "",
      status: "",
      id: "",
      createdAt: "",
    };
  },
  methods: {
    toggle() {
      this.editMode = !this.editMode;
    },
    format(val) {
      return dayjs(val).format("D MMM YY");
    },
    capital(el) {
      return el.charAt(0).toUpperCase() + el.slice(1);
    },
    async post() {
      let data = {
        title: this.title,
        category: this.category.value,
        status: this.status.value,
        id: this.supportTicket.id,
        content: this.description,
      };
      await this.$store.dispatch("supportTickets/putSupportTicket", data);
      this.toggle();
    },
    close() {
      this.loadData();
      this.toggle();
    },
    loadData() {
      this.title = this.supportTicket.title;
      this.category = this.supportTicket.category;
      this.status = this.capital(this.supportTicket.status);
      this.createdAt = this.format(this.supportTicket.createdAt);
      this.description = this.supportTicket.content;
    },
  },
  computed: {
    ...mapState("supportTickets", ["supportTicket", "categories"]),
    itemsStatus() {
      return ["open", "in progress", "resolved", "closed"].map((el) => {
        let capital = el.charAt(0).toUpperCase() + el.slice(1);
        let selected = this.supportTicket.status == el;
        return { display: capital, value: el, selected: selected };
      });
    },
    itemsCategories() {
      return this.categories.map((el) => {
        let capital = el.charAt(0).toUpperCase() + el.slice(1);
        let selected = this.supportTicket.category == el;
        return { display: capital, value: el, selected: selected };
      });
    },
  },

  async mounted() {
    this.loadData();
    let loc = "supportTickets/getSupportTicketsByID";
    await this.$store.dispatch(loc, this.$route.params.id);
    this.loadData();
  },
};
</script>
  
  <style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  border-bottom: 1px solid #42515e;
}

.item {
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
  border-top: 1px solid #42515e;
}
</style>